<template>
  <div class="animated fadeIn">
    <b-form
      novalidate
      @submit.prevent="onSubmit"
    >
      <div class="main-info">
        <div class="main-info__body">
          <h1 class="main-info__body-title">
            Редактирование габарита «{{ data.name }}»
          </h1>
          <b-row>
            <b-col
              md="4"
            >
              <div class="main-info__item">
                <p class="main-info__item-title">
                  Тоннаж<span class="text-danger">*</span>
                </p>
                <b-form-group
                  id="usernameInputGroup1"
                  class="form-group-el-select"
                >
                  <b-form-input
                    id="weight"
                    v-model="form.weight"
                    type="number"
                    name="weight"
                    min="0"
                    placeholder="Введите тоннаж"
                    autocomplete="weight"
                    @wheel="$event.target.blur()"
                  />
                </b-form-group>
              </div>
            </b-col>
            <b-col
              md="4"
            >
              <div class="main-info__item">
                <p class="main-info__item-title">
                  Объем от<span class="text-danger">*</span>
                </p>
                <b-form-group
                  id="usernameInputGroup1"
                  class="form-group-el-select"
                  description="Значение указывается в метрах кубических"
                >
                  <b-form-input
                    id="route-price"
                    v-model="form.volumeFrom"
                    type="number"
                    name="route-price"
                    min="0"
                    placeholder="Введите объем от"
                    autocomplete="route-price"
                    @wheel="$event.target.blur()"
                  />
                </b-form-group>
              </div>
            </b-col>
            <b-col
              md="4"
            >
              <div class="main-info__item">
                <p class="main-info__item-title">
                  Объем до<span class="text-danger">*</span>
                </p>
                <b-form-group
                  id="usernameInputGroup1"
                  class="form-group-el-select"
                  description="Значение указывается в метрах кубических"
                >
                  <b-form-input
                    id="route-price"
                    v-model="form.volumeUpTo"
                    type="number"
                    name="route-price"
                    min="0"
                    placeholder="Введите объем до"
                    autocomplete="route-price"
                    @wheel="$event.target.blur()"
                  />
                </b-form-group>
              </div>
            </b-col>
            <b-col
              md="4"
            >
              <div class="main-info__item">
                <p class="main-info__item-title">
                  Основной габарит по умолчанию
                </p>
                <b-form-group
                  id="acceleratedInputGroup3"
                >
                  <b-form-checkbox
                    id="isDefault"
                    v-model="form.isDefault"
                    name="isDefault"
                    :disabled="form.isOptionalDefault"
                  >
                    Да
                  </b-form-checkbox>
                </b-form-group>
              </div>
            </b-col>
            <b-col
              md="4"
            >
              <div class="main-info__item">
                <p class="main-info__item-title">
                  Дополнительный габарит по умолчанию
                </p>
                <b-form-group
                  id="acceleratedInputGroup3"
                >
                  <b-form-checkbox
                    id="isOptionalDefault"
                    v-model="form.isOptionalDefault"
                    name="isOptionalDefault"
                    :disabled="form.isDefault"
                  >
                    Да
                  </b-form-checkbox>
                </b-form-group>
              </div>
            </b-col>
          </b-row>
          <h2 class="main-info__body-subtitle">
            Отношения опциональных габаритов
          </h2>
          <table class="main-info__table table b-table table-responsive">
            <thead>
              <tr>
                <th>ID</th>
                <th>Габариты ТС</th>
                <th>Процент</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(dimension, index) in calculate">
                <td>{{dimension.optionalDimension.id}}</td>
                <td>
                  <el-select-clearable
                    v-model="dimension.optionalDimension.id"
                    :disabled="dimension.optionalDimension.id == dimensionId"
                    name="dimensions"
                    placeholder="Выберите габарит"
                    :loading="loading"
                    @focus="getAllDimensions"
                  >
                    <el-option
                      v-for="item in data.dimensionsList"
                      :key="item.value"
                      :label="item.text"
                      :value="item.value"
                      :disabled="item.disabled"
                    />
                  </el-select-clearable>
                </td>
                <td>
                  <b-form-input
                    id="route-price"
                    v-model="dimension.percentage"
                    :disabled="dimension.optionalDimension.id == dimensionId"
                    type="number"
                    name="route-price"
                    min="0"
                    max="9999"
                    placeholder=""
                    autocomplete="route-price"
                    @wheel="$event.target.blur()"
                  />
                </td>
                <!--td>
                  <a
                    v-if="$store.getters.isAdmin && dimension.optionalDimension.id != dimensionId"
                    v-b-tooltip.hover.bottom="'Удалить'"
                    class="fa fa-close fa-lg text-danger table-action-button"
                    @click="deleteDimensionCalculate(index)"
                  />
                </td-->
              </tr>
            </tbody>
          </table>
          <b-button
            v-if="$store.getters.isAdmin"
            variant="primary"
            size="sm"
            @click="addDimensionCalculate()"
          >
            <i class="fa fa-plus" />&nbsp;Добавить
          </b-button>
        </div>
      </div>
      <div class="fixed-buttons">
        <b-button
          type="button"
          variant="secondary"
          class="mr-2"
          :class="{'loading' : loading}"
          @click="$router.go(-1)"
        >
          Назад
        </b-button>
        <b-button
          type="submit"
          variant="primary"
          class="mr-2"
          :class="{'loading' : loading}"
          :disabled="loading || !form.weight || !form.volumeFrom || !form.volumeUpTo"
        >
          Сохранить
        </b-button>
      </div>
    </b-form>
  </div>
</template>

<script>
import ElSelectClearable from '../../components/ElSelectClearable.vue';
import {
  dimensionCalculateRead,
  dimensionCalculateUpdate,
  transportVehiclesDimensionsTypes,
  transportVehiclesDimensionTypeRead,
  transportVehiclesDimensionTypeUpdate,
} from '../../services/api';
import {validationMixin} from 'vuelidate';
import notifications from '../../components/mixins/notifications';

export default {
  name: 'CustomerDimensionEdit',
  components: {
    ElSelectClearable,
  },
  mixins: [validationMixin, notifications],
  props: ['dimensionId'],
  data() {
    return {
      data: {
        name: null,
        dimensionsList: null,
      },
      calculate: [],
      form: {
        weight: null,
        volumeFrom: null,
        volumeUpTo: null,
        isDefault: false,
        isOptionalDefault: false,
      },
      loading: false,
    };
  },
  computed: {
    formStr() {
      const calculationFormData = {};
      if (this.form.weight) {
        calculationFormData.weight = this.form.weight;
      }
      if (this.form.volumeFrom) {
        calculationFormData.volumeFrom = this.form.volumeFrom;
      }
      if (this.form.volumeUpTo) {
        calculationFormData.volumeUpTo = this.form.volumeUpTo;
      }
      calculationFormData.isDefault = this.form.isDefault;
      calculationFormData.isOptionalDefault = this.form.isOptionalDefault;
      return calculationFormData;
    },
    calculateStr() {
      return this.calculate.filter((item) => item.optionalDimension.id);
    },
  },
  mounted() {
    this.getDimension();
    this.getDimensionCalculate();
    this.getAllDimensions();
  },
  methods: {
    async onSubmit() {
      this.transportVehiclesDimensionTypeUpdate();
    },
    async transportVehiclesDimensionTypeUpdate() {
      this.loading = true;
      const response = await transportVehiclesDimensionTypeUpdate(this.$store.state.user.ownerId, this.dimensionId, this.formStr);
      if (response && response.status === 200) {
        this.showSuccess('Габарит обновлен');
        this.dimensionCalculateUpdate();
      }
      this.loading = false;
    },
    async dimensionCalculateUpdate() {
      this.loading = true;
      const response = await dimensionCalculateUpdate(this.$store.state.user.ownerId, this.dimensionId, this.calculateStr);
      if (response && response.status === 200) {
        this.showSuccess('Коэфициенты габарита обновлены');
        this.$router.push({path: `/dimensions/customer/view/id${this.dimensionId}`});
      }
      this.loading = false;
    },
    async getDimension() {
      this.loading = true;
      const response = await transportVehiclesDimensionTypeRead(this.$route.params.dimensionId);
      if (response && response.status === 200) {
        this.data.name = response.data.name;
        if (this.calculate.length === 0) {
          this.calculate = [{
            mainDimension: {
              id: Number(this.dimensionId),
            },
            optionalDimension: {
              id: Number(this.dimensionId),
            },
            percentage: 100,
          }];
        }
        this.form.weight = Number(response.data.weight);
        this.form.volumeFrom = Number(response.data.volumeFrom);
        this.form.volumeUpTo = Number(response.data.volumeUpTo);
        this.form.isDefault = response.data.isDefault;
        this.form.isOptionalDefault = response.data.isOptionalDefault;
      }
      this.loading = false;
    },
    async getDimensionCalculate() {
      this.loading = true;
      const response = await dimensionCalculateRead(this.$store.state.user.ownerId, this.$route.params.dimensionId, {
        limit: 100,
      });
      if (response && response.status === 200) {
        if (response.data.items.length > 0) {
          this.calculate = response.data.items;
        }
      }
      this.loading = false;
    },
    async getAllDimensions() {
      this.loading = true;
      const response = await transportVehiclesDimensionsTypes({limit: 100});
      if (response && response.status === 200) {
        this.data.dimensionsList = response.data.items.map((item) => {
          let disabled = false;
          this.calculate.forEach((calc) => {
            if (calc.optionalDimension.id === item.id) {
              disabled = true;
            }
          });
          return {value: item.id, text: item.name, disabled: disabled};
        });
      }
      this.loading = false;
    },
    addDimensionCalculate() {
      this.calculate.push({
        mainDimension: {
          id: Number(this.dimensionId),
        },
        optionalDimension: {
          id: null,
        },
        percentage: null,
      });
    },
    deleteDimensionCalculate(chosenIndex) {
      this.calculate = this.calculate.filter((item, index) => index !== chosenIndex);
    },
    clearDimensionInput(chosenIndex) {
      this.data.dimensionsList = this.data.dimensionsList.filter((item, index) => index !== chosenIndex);
    },
    changeDimensionInput(chosenIndex) {
      this.data.dimensionsList = this.data.dimensionsList.filter((item, index) => index !== chosenIndex);
    },
  },
};
</script>
